.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.buttons-row {
  display: flex;
  justify-content: space-around;
  padding: 30px;
}

.data-placeholder {
  display: flex;
  justify-content: center;
  width: 80%;
  padding: 50px 10px 10px 10px;
}

.cta-button {
  margin-left: 50px;
  margin-right: 50px;
  min-width: 200px;
}